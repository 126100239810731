<template>
  <div>
    <div class="white--text text-center">
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="notificationType"
        top
      >
        {{ notificationText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            <div class="white--text">
              Close
            </div>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <div v-if="user">
      <v-img class="cover-image"
      :src="user.profile_background_image ? user.profile_background_image
      : require(`@/assets/Mask Group 6.png`)"
      >
      </v-img>
      <section class="content-section">
        <div class="cover-button">
          <v-btn :loading="coverPhotoBtnLoad" @click="cover = 1; profile = 0; photosmodal = true;" color="primary" absolute top right class="mt-5">
            <v-icon left>mdi-camera</v-icon>
            Edit cover photo
          </v-btn>
        </div>
        <div>
          <p v-if="user.slogan" class="user-slogan primaryDark--text text-bold heading">({{user.slogan}})</p>
          <div class="profile-photo ml-15">
            <div class="avatar-container">
              <v-avatar size="200" :color="user.profile_photo ? 'white' : 'primary'" class="text-bold white--text heading">
                <v-img v-if="user.profile_photo !== null" :src="user.profile_photo"></v-img>
                <span v-else>{{ user.full_name.split(' ')[0].charAt(0) + user.full_name.split(' ')[1].charAt(0) }}</span>
              </v-avatar>
              <v-btn :loading="updateProfileBtnLoad" @click="profile = 1; cover = 0; profilePhotosModal = true;" small
              class="avatar-button" fab
              color="grey lighten-2" absolute bottom>
                <v-icon>mdi-camera</v-icon>
              </v-btn>
              <div class="text-center">
                <v-dialog
                  v-model="photosmodal"
                  width="500"
                >
                  <!-- <template v-slot:activator="{ on, attrs }">
                    <v-btn :loading="updateProfileBtnLoad" @click="profile = 1" v-bind="attrs" v-on="on" small
                           class="avatar-button" fab
                           color="grey lighten-2" absolute bottom>
                      <v-icon>mdi-camera</v-icon>
                    </v-btn>
                  </template> -->

                  <v-card class="pa-5">
                    <v-form ref="photoForm" v-model="photoModalValid">
                      <v-card-title v-if="cover === 1" class="primary--text">
                        Change cover
                      </v-card-title>
                      <!-- <v-card-title v-if="profile === 1" class="primary--text">
                        Change profile photo
                      </v-card-title> -->
                      <v-file-input v-if="cover === 1" outlined v-model="coverPhoto" label="Choose photo"
                                    accept="image/*"
                                    :rules="[rules.required]"></v-file-input>
                      <!-- <v-file-input v-if="profile === 1" outlined v-model="profilePhoto" accept="image/*"
                                    label="Choose photo" :rules="[rules.required]"></v-file-input> -->
                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <!-- <v-btn v-if="profile === 1"
                               color="primary"
                               text
                               @click="changePhoto() ? photosmodal = false : photosmodal = true"
                        >
                          Upload
                        </v-btn> -->
                        <v-btn
                        color="primary"
                        text
                        @click="changePhoto() ? photosmodal = false : photosmodal = true"
                        >
                          Upload
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="photosmodal = false; profile = 0; cover = 0"
                        >
                          Cancel
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
              </div>
            </div>
            <div class="user-data mt-10">
              <p class="full-name text-center primaryDark--text text-bold heading">{{ user.full_name }}</p>
              <p class="text-center primaryDark--text">{{ user.email }}</p>
            </div>
          </div>
        </div>
        <!-- image Dialog -->
        <v-dialog v-model="profilePhotosModal" width="50%">
          <v-card class="pa-5 text-center">
            <croppa
            v-model="croppa"
            :width="profile === 1 ? 150 : 300"
            :height="profile === 1 ? 150 : 200"
            :quality="2"
            prevent-white-space
            :placeholder-font-size="18"
            @init="onInit"
            ></croppa>
            <div class="btns">
              <v-btn icon color="primary darken-2" @click="croppa.zoomIn()"><v-icon>mdi-magnify-plus</v-icon></v-btn>
              <v-btn icon color="primary darken-2" @click="croppa.zoomOut()"><v-icon>mdi-magnify-minus</v-icon></v-btn>
            </div>
            <v-divider></v-divider>
            <v-btn class="d-block mx-auto" text color="primary" @click="changePhotos"><v-icon>mdi-download</v-icon> Save picture</v-btn>
          </v-card>
        </v-dialog>
        <v-row class="pr-5">
          <v-col cols="12" md="3">
            <sidebar></sidebar>
          </v-col>
          <v-col cols="12" md="9">
            <transition name="slide-fade">
              <router-view :key="$route.path" @success="showNotification('success',$event)"
                           @error="showNotification('error',$event)"></router-view>
            </transition>
          </v-col>
        </v-row>
      </section>
    </div>
  </div>
</template>

<script>
import sidebar from '@/components/profile/sidebar.vue'
import user from '@/web_services/user'
import { mapState } from 'vuex'
// import { userdata, headers } from '../../endpoints'
export default {
  components: { sidebar },
  data () {
    return {
      coverPhotoBtnLoad: null,
      snackbar: false,
      notificationText: null,
      timeout: 3000,
      notificationType: null,
      userNameSliced: null,
      photosmodal: 0,
      updateProfileBtnLoad: null,
      profile: 0,
      cover: 0,
      coverPhoto: null,
      profilePhoto: null,
      photoModalValid: true,
      rules: { required: value => !!value || 'This field Required.' },
      photosModal: false,
      profilePhotosModal: false,
      croppa: {},
      image: {}
    }
  },
  methods: {
    changePhotos () {
      // if (this.$refs.photoFormAbout.validate()) {
      // if (this.photoModalAboutValid) {
      this.croppa.generateBlob((blob) => {
        blob.lastModifiedDate = new Date()
        blob.name = 'user_profile_image_' + new Date().toDateString()
        this.image = blob
        this.changePhoto()
      })
    },
    onInit () {
      if (this.profile === 1 && this.cover === 0) {
        this.croppa.addClipPlugin(function (ctx, x, y, w, h) {
          ctx.beginPath()
          ctx.arc(x + w / 2, y + h / 2, w / 2, 0, 2 * Math.PI, true)
          ctx.closePath()
        })
      }
    },
    changePhoto () {
      // if (this.$refs.photoForm.validate()) {
      // if (this.photoModalValid) {
      if (this.profile === 1) {
        this.updateProfileBtnLoad = true
        const formData = new FormData()
        this.profilePhoto = this.image
        formData.append('profile_photo', this.profilePhoto, this.profilePhoto.name)
        user.update(formData).then(() => {
          user.profile().then(res => {
            this.$store.dispatch('setUserData', res.data.data)
          }).catch(() => {
            this.$emit('error', 'Something went wrong')
          })
        }).catch(() => {
          this.$emit('error', 'Something went wrong')
        }).finally(() => {
          this.profile = 0
          this.updateProfileBtnLoad = false
          this.profilePhotosModal = false
        })
      } else if (this.cover === 1) {
        this.coverPhotoBtnLoad = true
        // this.coverPhoto = this.image
        const formData = new FormData()
        formData.append('profile_background_image', this.coverPhoto, this.coverPhoto.name)
        user.update(formData).then(() => {
          user.profile().then(res => {
            this.$store.dispatch('setUserData', res.data.data)
          }).catch(() => {
          })
        }).catch(() => {
        }).finally(() => {
          this.coverPhotoBtnLoad = false
          this.cover = 0
          this.photosmodal = false
        })
      }
      // }
      // }
      // return false
    },
    showNotification (color, text) {
      this.snackbar = true
      this.notificationType = color
      this.notificationText = text
    }
  },
  computed: {
    ...mapState(['user'])
  }
}

</script>

<style>
.profile {
  height: 400px;
  background: -webkit-image-set(url('../../assets/images/home-sm.jpg') 1x, url('../../assets/images/home-lg.jpg') 2x);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  overflow-y: visible;
}

.profile-photo {
  width: fit-content;
}

.content-section {
  transform: translateY(-100px);
}

.v-text-field--outlined fieldset {
  border-color: #cecece !important;
  border-width: 0.5px;
}

.cover-image {
  max-width: 100%;
  height: 400px;
}

.cover-button {
  z-index: 1;
}

.user-slogan {
  text-align: center;
  position: absolute;
  top: 140px;
  left: 280px;
  font-size: 40px;
}
.full-name {
  font-size: 45px;
}
</style>
